<template>
  <div>
    <v-text-field
      outlined
      dense
      label="Rechercher une entreprise..."
      v-model="search"
    ></v-text-field>
    <v-card class="mx-auto" outlined>
      <v-list two-line v-if="entreprises">
        <v-list-item-group>
          <template v-for="(entreprise, index) in filteredCompanies">
            <v-list-item
              :key="entreprise.id"
              @click="$router.push(`/companies/${entreprise.id}`)"
            >
              <template v-slot:default="{}">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="entreprise.nom"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    class="text--primary"
                    v-text="entreprise.presentation.text"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-text="
                      entreprise.nombreEmployes
                        ? `${entreprise.nombreEmployes.nombre} employés`
                        : '-'
                    "
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text
                    v-if="entreprise.canton"
                    v-text="entreprise.canton.nom"
                  ></v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              v-if="
                index < filteredCompanies.length - 1 &&
                filteredCompanies.length > 1
              "
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data: () => ({ search: "", entreprises: [] }),
  props: ["student"],
  async mounted() {
    const {
      data: { entreprises },
    } = await this.$apollo.query({
      query: gql`
        query loadCompanies {
          entreprises {
            id
            nom
            presentation {
              text
            }
            canton {
              nom
            }
            nombreEmployes {
              nombre
            }
          }
        }
      `,
      variables() {
        return {
          searchString: this.search,
        };
      },
    });
    this.entreprises = entreprises;
  },
  computed: {
    filteredCompanies() {
      return this.entreprises.filter(
        (c) => c.nom.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },
  },
};
</script>
