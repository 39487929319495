<template>
  <div>
    <v-card class="ma-sm-10" outlined>
      <v-card-text class="text-center">
        <div class="font-weight-bold">08h45 – 09h45 : Séance plénière</div>
        <div class="caption">
          (Discours des Doyens HEG et COMEM, Présentation des entreprises
          présentes)
        </div>
        <div class="font-weight-bold mt-5">09h45 – 10h00 : Pause</div>
        <div class="font-weight-bold mt-5">
          10h00 – 12h00 : Rencontre entre les entreprises et les étudiant·e·s
        </div>
        <div class="caption">(Vos rendez-vous apparaissent ci-dessous)</div>
      </v-card-text>
    </v-card>
    <agenda-form />
  </div>
</template>

<script>
import AgendaForm from "@/components/AgendaForm";

export default {
  components: { AgendaForm },
};
</script>

