import Vue from "vue";
import App from "./App.vue";
import Closed from "./Closed.vue";
import vuetify from "./plugins/vuetify";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
import router from "./router";
import { createProvider } from "./vue-apollo";
import { setContext } from "apollo-link-context";

Vue.config.productionTip = false;

const closed = false

const tokenInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const init = { onLoad: "login-required" };
if (!isChrome) {
  init.checkLoginIframe = false;
}

if (closed) {
  new Vue({
    vuetify,
    render: (h) => h(Closed),
  }).$mount("#app");
} else {
  Vue.use(VueKeyCloak, {
    init,
    config: {
      realm: process.env.VUE_APP_KC_REALM,
      url: process.env.VUE_APP_KC_URL,
      clientId: process.env.VUE_APP_KC_CLIENT_ID,
    },
    onReady: async (keycloak) => {
      if (keycloak === true) {
        keycloak.logout();
      } else {
        tokenInterceptor();
        try {
          const { data: token } = await axios({
            method: "get",
            url: `${process.env.VUE_APP_API_URI}/user-context/token`,
          });
          const authLink = setContext(async (_, { headers }) => {
            return {
              headers: {
                ...headers,
                authorization: `Bearer ${token}`,
              },
            };
          });
          const options = { link: authLink };
          new Vue({
            vuetify,
            router,
            apolloProvider: createProvider(options),
            render: (h) => h(App),
          }).$mount("#app");
        } catch (error) {
          keycloak.logout();
        }
      }
    },
  });
}
