<template>
  <v-card outlined class="pa-md-10 pa-sm-0" v-if="student">
    <v-container>
      <v-row>
        <v-col>
          <v-alert
            outlined
            color="primary"
            icon="mdi-information-outline"
            prominent
            border="left"
            class="text-left"
          >
            <div class="my-2">
              Les informations renseignées sur votre profil, y compris votre CV,
              apparaîtront sur la plateforme destinée aux entreprises dès le 4
              octobre. Celle-ci ne sera pas référencée sur les moteurs de
              recherche mais sera tout de même publique, veillez donc à ne pas
              divulguer d’informations sensibles.
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col
          ><v-avatar
            v-if="!student.picture"
            color="grey"
            size="86"
            v-text="shortName"
            class="white--text text-h5"
            @click="handleFileImport"
            style="cursor: pointer"
          ></v-avatar>
          <v-avatar
            v-else
            size="86"
            class="white--text text-h5"
            @click="handleFileImport"
            style="cursor: pointer"
            ><img :src="`${apiUri}/${student.picture}`" alt="student-picture"
          /></v-avatar>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileChanged"
          />
          <div class="headline mt-4">
            {{ student.firstname }} {{ student.lastname }}
          </div>
          <div class="subtitle">{{ student.filiere }}</div></v-col
        >
      </v-row>
      <v-row class="mt-0" justify="center">
        <v-col sm="4">
          <div v-if="student.cv" class="text-center">
            <a @click="downloadDocument(student.cv)" style="color: black">
              <v-icon x-large>mdi-file-outline</v-icon>
              <div>{{ student.cv.substr(22) }}</div>
            </a>
            <v-btn outlined small class="mt-2" @click="removeDocument"
              >Supprimer le CV</v-btn
            >
          </div>
          <v-file-input
            v-else
            outlined
            dense
            label="Curriculum Vitae"
            @change="handleUpload"
            :loading="uploading"
        /></v-col>
      </v-row>
      <v-row class="text-center">
        <v-col
          ><v-textarea
            outlined
            dense
            auto-grow
            label="Présentation"
            v-model="student.description"
            counter="500"
            maxlength="500"
          ></v-textarea
        ></v-col>
      </v-row>
      <v-btn outlined block class="mt-10" @click="saveProfile"
        >Sauvegarder mon profil</v-btn
      >
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.visible = false">
            Fermer
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-model="cropDialog" max-width="600px">
        <v-card>
          <v-card-title>Recadrer l'image</v-card-title
          ><v-card-text class="text--center"
            ><cropper
              class="cropper"
              ref="cropper"
              :src="cropSrc"
              @change="onChange"
              :stencil-props="{
                handlers: {},
                movable: false,
                scalable: false,
              }"
              :stencil-size="{
                width: 280,
                height: 280,
              }"
              image-restriction="stencil" /></v-card-text
          ><v-card-actions
            ><v-spacer></v-spacer
            ><v-btn outlined dense @click="uploadPicture"
              >OK</v-btn
            ></v-card-actions
          ></v-card
        ></v-dialog
      >
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import FileDownload from "js-file-download";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}
export default {
  name: "ProfileForm",
  props: ["student"],
  components: { Cropper },
  data: () => ({
    uploading: false,
    snackbar: { visible: false, text: "", color: "" },
    isSelecting: false,
    selectedFile: null,
    apiUri: process.env.VUE_APP_API_URI,
    cropDialog: false,
    cropSrc: "",
    image: {
      src: null,
      type: null,
    },
  }),
  computed: {
    shortName: function () {
      return `${this.$keycloak.tokenParsed.given_name.substr(
        0,
        1
      )}${this.$keycloak.tokenParsed.family_name.substr(0, 1)}`;
    },
  },
  destroyed() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
  methods: {
    reset() {
      this.image = {
        src: null,
        type: null,
      };
    },
    loadImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    onChange({ canvas }) {
      this.image = canvas.toDataURL();
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    async uploadPicture() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const form = new FormData();
        canvas.toBlob(async (blob) => {
          form.append("file", blob);
          try {
            const { data } = await axios({
              method: "post",
              url: `${process.env.VUE_APP_API_URI}/uploads-picture`,
              data: form,
              headers: {
                "content-Type": "multipart/form-data",
                "x-api-key": process.env.VUE_APP_API_KEY,
              },
            });
            this.student.picture = data.filename;
            this.cropDialog = false;
          } catch (e) {
            this.text = "Erreur de chargement du fichier";
            this.color = "error";
            this.snackbar = {
              visible: true,
              color: "error",
              text: "Erreur de chargement",
            };
            this.uploading = false;
          } finally {
            this.selectedFile = null;
            this.selecting = false;
          }
        }, this.image.type);
      }
    },
    async onFileChanged(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.cropSrc = e.target.result;
          this.cropDialog = true;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async downloadDocument(filename) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${filename}`,
        responseType: "arraybuffer",
      });
      FileDownload(data, filename.substr(22));
    },
    async saveProfile() {
      try {
        await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/user-context`,
          data: {
            description: this.student.description,
            cv: this.student.cv,
            picture: this.student.picture,
          },
        });
        this.snackbar = {
          visible: true,
          color: "success",
          text: "Profil sauvegardé",
        };
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de sauvegarde",
        };
      }
    },
    async handleUpload(file) {
      if (file) {
        this.uploading = true;
        try {
          const formData = new FormData();
          formData.append("file", file);
          const { data } = await axios({
            method: "post",
            url: `${process.env.VUE_APP_API_URI}/uploads`,
            data: formData,
            headers: {
              "content-Type": "multipart/form-data",
              "x-api-key": process.env.VUE_APP_API_KEY,
            },
          });
          this.student.cv = data.filename;
          this.uploading = false;
        } catch (e) {
          this.text = "Erreur de chargement du fichier";
          this.color = "error";
          this.snackbar = {
            visible: true,
            color: "error",
            text: "Erreur de chargement",
          };
          this.uploading = false;
        }
      }
    },
    removeDocument() {
      this.student.cv = "";
    },
  },
};
</script>

<style>
.cropper {
  height: 600px;
  width: 600px;
  background: #ddd;
}
</style>
