var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.agenda && _vm.contactPersons)?_c('div',{staticClass:"ma-sm-10"},_vm._l((_vm.slots),function(slot){return _c('v-card',{key:slot.toString(),staticClass:"mt-5",attrs:{"outlined":"","ripple":false,"disabled":!_vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); }
      ),"to":_vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); }
      )
        ? ("/companies/" + (_vm.contactPersons.find(
              function (cp) { return cp.id ===
                _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); }
                ).contactPersonId; }
            ).entreprises[0].id))
        : ''}},[(
        _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); }
        )
      )?_c('v-card-title',[_vm._v(_vm._s(_vm.contactPersons.find( function (cp) { return cp.id === _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); } ).contactPersonId; } ).prenom)+" "+_vm._s(_vm.contactPersons.find( function (cp) { return cp.id === _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); } ).contactPersonId; } ).nom)+" "),_c('span',{staticClass:"body-2 ml-1"},[_vm._v(" ("+_vm._s(_vm.contactPersons.find( function (cp) { return cp.id === _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); } ).contactPersonId; } ).fonction)+", "+_vm._s(_vm.contactPersons.find( function (cp) { return cp.id === _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); } ).contactPersonId; } ).entreprises[0].nom)+")")])]):_c('v-card-title',[_vm._v("Libre")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.format(slot, "HH:mm"))+"-"+_vm._s(_vm.format(_vm.addMinutes(slot, 10), "HH:mm")))]),(
        _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); }
        )
      )?_c('v-card-text',[(
          _vm.contactPersons.find(
            function (cp) { return cp.id ===
              _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); }
              ).contactPersonId; }
          ).teamschannel
        )?_c('div',[_c('div',{staticClass:"subtitle-2"},[_vm._v("Canal Teams")]),_c('a',{attrs:{"href":_vm.contactPersons.find(
              function (cp) { return cp.id ===
                _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); }
                ).contactPersonId; }
            ).teamschannel,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}},[_vm._v(_vm._s(_vm.contactPersons.find( function (cp) { return cp.id === _vm.agenda.find(function (a) { return a.slots.find(function (s) { return _vm.isEqual(_vm.parseISO(s.start), slot); }); } ).contactPersonId; } ).teamschannel))])]):_c('div',[_vm._v("Canal Teams pas encore annoncé")])]):_vm._e()],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }