var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Rechercher une entreprise..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[(_vm.entreprises)?_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',[_vm._l((_vm.filteredCompanies),function(entreprise,index){return [_c('v-list-item',{key:entreprise.id,on:{"click":function($event){return _vm.$router.push(("/companies/" + (entreprise.id)))}},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(entreprise.nom)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(entreprise.presentation.text)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                    entreprise.nombreEmployes
                      ? ((entreprise.nombreEmployes.nombre) + " employés")
                      : '-'
                  )}})],1),_c('v-list-item-action',[(entreprise.canton)?_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(entreprise.canton.nom)}}):_vm._e()],1)]}}],null,true)}),(
              index < _vm.filteredCompanies.length - 1 &&
              _vm.filteredCompanies.length > 1
            )?_c('v-divider',{key:index}):_vm._e()]})],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }