<template>
  <v-app>
    <v-app-bar app flat color="grey lighten-4">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" overlay-opacity="0">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar tile>
            <img
              src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>REE 2021</v-list-item-title>
            <v-list-item-subtitle>Interface étudiant</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link to="/profile">
          <v-list-item-icon>
            <v-icon>mdi-school-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mon profil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/agenda">
          <v-list-item-icon>
            <v-icon>mdi-calendar-today</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mon agenda</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/companies">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Entreprises</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="ma-md-10 ma-sm-0">
      <router-view :student="student" />
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import axios from "axios";

export default {
  name: "App",
  components: { UserProfile },
  async mounted() {
    const { data: student } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/user-context`,
    });

    this.student = student;
  },
  data: () => ({
    fab: false,
    drawer: null,
    student: null,
    token: null,
  }),
};
</script>

<style>
.separator {
  display: inline-block;
}
</style>